<template>
	<div class="animated fadeIn">
		<b-card>
			<div class="display-table full-width mb-2">
				<strong class="card-title">{{$route.meta.label}}</strong>
			</div>
			<b-row>
				<ResponseAlert ref="response" />
				<div class="col-sm-12 mb-10">
					<b-row class="align-items-center justify-content-end">
						<b-col sm="2">
							<b-button
								@click="exportMembership"
								class="btn btn-primary display-table-cell pull-right rounded text-white w-100"
								size="sm">
								Export Membership
							</b-button>
						</b-col>
						<b-col sm="3">
							<b-form-select v-model="config.additional_params.membership_status" :options="masters.membershipStatuses"></b-form-select>
						</b-col>
						<b-col sm="2">
							<b-form-select v-model="config.additional_params.package_id" :options="masters.packages"></b-form-select>
						</b-col>
						<b-col sm="2">
							<b-form-select v-model="config.additional_params.status" :options="masters.statuses"></b-form-select>
						</b-col>
						<b-col sm="3">
							<div class="input-group">
								<input type="text" placeholder="Search name or email" v-model="config.search.key" v-on:keyup.enter="get()"
									class="form-control form-control-sm" />
								<div class="input-group-prepend">
									<div class="input-group-text" v-on:click="get()">
										<i class="fa fa-search"></i>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
				<b-col sm="12">
					<div class="table-responsive">
						<table class="table b-table">
							<thead>
								<tr>
									<th v-for="(row, key) in config.headers" :width="row.width" :key="key"
										:class="row.class">
										<a v-if="row.value" class="sort dark-font" v-on:click="sort(row.value)"
											href="javascript:void(0)">
											{{row.title}}
											<i :id="row.value" class="fa fa-sort"></i>
										</a>
										<a v-else class="sort dark-font" :id="row.value" href="javascript:void(0)">
											{{row.title}}
										</a>
									</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="config.total_data">
									<tr v-for="(row, key) in config.rows" :key="key">
										<td>{{row.first_name}} {{row.last_name}}</td>
										<td>{{row.email}}</td>
										<td>{{row.package.name}}</td>
										<td>{{moment(row.created_at).format('DD/MM/YYYY')}}</td>
										<td>{{row.membership_expired_at ? moment(row.membership_expired_at).format('DD/MM/YYYY') : '-'}}</td>
										<td>
											<span :class="'badge badge-' + getClassStatus(row.membership_status)">
												{{row.membership_status}}
											</span>
										</td>
										<td class="d-flex justify-content-center">
											<button v-if="row.is_employee && row.membership_status === 'waiting approval'" @click="changeStatus('active', row.id)" title="Approve" class="btn-action">
												<i class="fa fa-check text-success"></i>
											</button>
											<button v-if="row.is_employee && row.membership_status === 'waiting approval'" title="Reject" class="btn-action" @click="reject('inactive', row.id)">
												<i class="fa fa-close text-danger"></i>
											</button>
											<router-link :to='`/${config.uri}/${row.id}`' title="Detail"
												class="btn-action">
												<i class="fa fa-eye"></i>
											</router-link>
										</td>
									</tr>
								</template>
								<tr v-if="!config.total_data">
									<td :colspan="config.headers.length" align="center"> No data available. </td>
								</tr>
							</tbody>
							<tfoot v-if="config.total_data">
								<tr>
									<td :colspan="config.headers.length" class="no-padding">
										<div class="pull-left table-information mt-25">
											<p>Showing {{config.page}} to {{config.total_page}} of {{config.total_data}}
												entries</p>
										</div>
										<div class="pull-right pagination mt-25">
											<b-pagination @change="gotoPage" :total-rows="config.total_data"
												v-model="config.page" :per-page="config.per_page">
											</b-pagination>
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>
<script>
	import moment from 'moment'
	import table from '@/utils/table.js'
	import api from '@/utils/api.js'
	import ResponseAlert from '@/components/response_alert';
	import { getClassStatus } from '@/utils/helpers';
	export default {
		name: 'ListMembership',
		components: {
			ResponseAlert,
		},
		data() {
			return {
				moment,
				masters: {
					membershipStatuses: [
						{ value: '', text: 'Status Membership' },
						{ value: 'active', text: 'Active' },
						{ value: 'inactive', text: 'Inactive' },
						{ value: 'waiting approval', text: 'Waiting Approval' },
					],
					statuses: [
						{ value: null, text: 'Status Account' },
						{ value: 'active', text: 'Active' },
						{ value: 'inactive', text: 'Inactive' },
					],
					packages: [],
				},
				config: {
					uri: this.$route.meta.permission.link,
					api: api.user,
					rows: [
					],
					total_data: 0,
					total_page: 0,
					per_page: 10,
					page: 1,
					orderby: "id",
					sort: "desc",
					additional_params: {
						package_id: null,
						is_membership: true,
						role_id: 1,
						status: null,
						membership_status: ''
					},
					search: {
						key: ""
					},
					headers: [{
						title: "Full Name",
						value: "first_name",
						align: "text-left",
						width: "20%",
					}, {
						title: "Email",
						value: "email",
						align: "text-left",
						width: "20%",
					},{
						title: "Package",
						value: false,
						align: "text-left",
						width: "10%",
					}, {
						title: "Registered At",
						value: false,
						align: "text-left",
						width: "15%",
					}, {
						title: "Expired At",
						value: false,
						align: "text-left",
						width: "15%",
					},{
						title: "Membership Status",
						value: "membership_status",
						align: "text-left",
						width: "10%",
					}, {
						title: "Action",
						value: false,
						class: "text-center",
						width: "10%",
					}],
				},
			}
		},
		created() {
			const _ = this;
			_.get();
			_.getPackages()
		},
		methods: {
			get() {
				table.get(this.config)
			},
			sort(orderby) {
				table.sort(this.config, orderby)
			},
			gotoPage(page) {
				table.gotoPage(this.config, page)
			},
			getClassStatus(status) {
				return getClassStatus(status);
			},
			getPackages() {
				const _ = this
				_.$axios.get(api.package, {
					params: {
						status: 'active',
						orderby: 'name',
						sort: 'asc',
					}
				}).then(res => {
					const packages = res.data.data.rows.map(p => {
						return {
							value: p.id,
							text: p.name
						}
					})
					_.masters.packages = [
						{ value: null, text: 'Package' },
						...packages]
				})
			},
			reject(membership_status, user_id) {
				const _ = this;
				_.$dialog.confirm('Are you sure want to reject this data?', {
						"okText": "Yes",
						"cancelText": "No"
					})
					.then(() => {
						this.changeStatus(membership_status, user_id)
					})
			},
			changeStatus(membership_status, user_id) {
        const _ = this
				const data = {
					membership_status
				}

				_.$axios.put(`${api.user}/membership_status/${user_id}`, data)
          .then(res => {
            _.$refs.response.setResponse(res.data.message, 'success');
            _.get()
          })
          .catch(error => {
            _.$refs.response.setResponse(error.response.data.message, 'danger')
          })
      },
			exportMembership(){
				this.$axios.get(`${api.user}/export?orderby=id&sort=asc&is_membership=true&membership_status=${this.config.additional_params.membership_status}`).then((response) => {
					const url = response.data.data.download_url
					const aEle = document.createElement('a');
					aEle.href = url;
					aEle.download = `Membership (${moment().format('DD MMMM YYYY')})`;
					document.body.appendChild(aEle);
					aEle.click();
					document.body.removeChild(aEle);
				});
			},
		},
		computed:{
			filterChange(){
				return `${this.config.additional_params.package_id}|${this.config.additional_params.status}|${this.config.additional_params.membership_status}`
			}
		},
		watch: {
			filterChange() {
				this.get()
			}
		}
	}
</script>